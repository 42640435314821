jQuery( document ).ready(function($) {

  AOS.init({
    duration: 1200,
    once: true,
  });

  // accordion
  $('.accordion-row-title').on('click', function(){
    $(this).parent().toggleClass('active');
  });
});
